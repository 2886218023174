/* eslint-disable */
// prettier-ignore
//icons TypeIcon, CreditCardIcon, BoldIcon, ActivityIcon, TypeIcon, LogInIcon, InfoIcon, HeartIcon
import store from '@/store/app'
const landingRoute = store.state.agent!='1dbaa403b94ccdd1991c3afd511858a5' ? { name: 'landing', query: { agent: store.state.agent } } : { name: 'landing' };
const quotationRoute = store.state.agent!='1dbaa403b94ccdd1991c3afd511858a5' ? { name: 'quotation', query: { agent: store.state.agent } } : { name: 'quotation' };
const productsRoute = store.state.agent!='1dbaa403b94ccdd1991c3afd511858a5' ? { name: 'products', query: { agent: store.state.agent } } : { name: 'products' };
const affiliateRoute = store.state.agent!='1dbaa403b94ccdd1991c3afd511858a5' ? { name: 'affiliate', query: { agent: store.state.agent } } : { name: 'affiliate' };
const contactRoute = store.state.agent!='1dbaa403b94ccdd1991c3afd511858a5' ? { name: 'contact', query: { agent: store.state.agent } } : { name: 'contact' };
const aboutRoute = store.state.agent!='1dbaa403b94ccdd1991c3afd511858a5' ? { name: 'about', query: { agent: store.state.agent } } : { name: 'about' };
const privacyRoute = store.state.agent!='1dbaa403b94ccdd1991c3afd511858a5' ? { name: 'privacy', query: { agent: store.state.agent } } : { name: 'privacy' };
const termsRoute = store.state.agent!='1dbaa403b94ccdd1991c3afd511858a5' ? { name: 'terms', query: { agent: store.state.agent } } : { name: 'terms' };
const paymentsRoute = store.state.agent!='1dbaa403b94ccdd1991c3afd511858a5' ? { name: 'payments', query: { agent: store.state.agent } } : { name: 'payments' };

export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Landing Page', route: landingRoute, icon: 'ShieldIcon', isBookmarked: false },
      { title: 'Quotation', route: quotationRoute, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Payments', route: paymentsRoute, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Terms of Use', route: termsRoute, icon: 'InfoIcon', isBookmarked: false },
      { title: 'Privacy Policy', route: privacyRoute, icon: 'InfoIcon', isBookmarked: false },
      { title: 'About Us', route: aboutRoute, icon: 'HeartIcon', isBookmarked: false },
      { title: 'Contact Us', route: contactRoute, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Affiliate Program', route: affiliateRoute, icon: 'BoldIcon', isBookmarked: false },
      { title: 'Products', route: productsRoute, icon: 'BoldIcon', isBookmarked: false },
    ],
  },
}
/* eslint-enable */
