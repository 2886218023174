<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul id="navBarLis" class="nav navbar-nav d-lg-none">
      <li class="nav-item">
        <b-link :to="linkLanding">
          <picture v-if="showAgent">
            <source media="(min-width: 768px)" :srcset="logo">
            <img class="img_logo_mobile" :src="logo_sm" alt="logo">
          </picture>
          <img v-else class="img_logo_mobile_noagent" :src="logo" alt="logo">
        </b-link>
      </li>
      <li class="nav-item" v-if="showAgent">
        <div id="agentDiv" class="d-flex align-items-center">
          <b-badge pill class="badgeAgent" variant="secondary">{{logoTxt}}</b-badge>
          <a :href="linkAgent">
            <img class="img_logo_mobile logolaptop" :src="agentLogoFunc" alt="agent logo">
          </a>
          <a :href="linkAgent">
            <img class="logomobile" :src="agentLogoMobileFunc" alt="agent logo">
          </a>
        </div>
      </li>
      <!-- <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li> -->
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
      <bookmarks />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar />
      <!-- <notification-dropdown /> -->
      <!-- <user-dropdown /> -->
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BBadge
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import { agentsMixin } from '@/products';
// import NotificationDropdown from './components/NotificationDropdown.vue'
// import UserDropdown from './components/UserDropdown.vue'

export default {
  mixins: [agentsMixin],
  data() {
    return {
      logo: require('@/assets/images/quotation/sft_logo.svg'),
      logo_sm: require('@/assets/images/quotation/small_sft.png'),
      logoba: require('@/assets/images/quotation/best_advice_logo.png'),
      logoinsline: require('@/assets/images/quotation/insuranceline.jpg'),
    }
  },
  components: {
    BLink,
    BBadge,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    // NotificationDropdown,
    // UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
<style scoped>
  .logomobile {
    display:none;
  }
  .img_logo_mobile {
    border-radius:8px;
  }
  .img_logo_mobile, .img_logo_mobile_noagent {
    object-fit:cover;
    display: block;
    max-width: 240px;
    width: 100%;
    height: auto;
    max-height:40px;
  }
  .badgeAgent {
    margin-left: 1rem;
  }
  @media screen and (max-width: 768px) {
    .badgeAgent {
      margin-left: 0.6rem;
      font-size:10px;
    }
  }
  @media screen and (max-width: 576px) {
    .logomobile,.img_logo_mobile {
      max-width: 280px;
      max-height: 35px;
      display:block;
      object-fit:cover;
    }
    .img_logo_mobile_noagent {
      max-width: 240px;
      max-height: 30px;
    }
    .logolaptop {
      display:none !important;
    }
  }
  @media screen and (max-width: 520px) {
    .navbar-container {
      padding: 0.8rem 0.5rem;
    }
  }
  @media screen and (max-width: 460px) {
    .img_logo_mobile_noagent {
      max-width: 200px;
      max-height: 25px;
    }
    .badgeAgent {
      display:none;
    }
    .logomobile {
      margin-left:3px;
    }
  }
  @media screen and (max-width: 400px) {
    .img_logo_mobile_noagent {
      max-width: 180px;
      max-height: 22px;
    }
  }
  @media screen and (max-width: 380px) {
    .logomobile,.img_logo_mobile {
      max-width: 250px;
      max-height: 30px;
    }
  }
  @media screen and (max-width: 360px) {
    .img_logo_mobile_noagent {
      max-width: 160px;
      max-height: 20px;
    }
  }
</style>
